import CEO from "../assets/images/People/Atuk1.jpg"
import CFO from "../assets/images/People/ferry-ok.jpg"
import CTO from "../assets/images/People/Valleryan.jpg"

const TimPeople = [
    {
        id: 1,
        title: "Rifi Zulhendri",
        icon: <img src={CEO} alt="Rifi Zulhendri - CEO" />,
        description:
            "Chef Executive Officer",
    },
    {
        id: 2,
        title: "Ferry Irawan",
        icon: <img src={CFO} alt="Ferry Irawan - CFO" />,
        description:
            "Chief Finance Officer",
    },
    {
        id: 3,
        title: "Valeryan Vale",
        icon: <img src={CTO} alt="Vale - CTO" />,
        description:
            "Chief Technology Officer",
    },
];

export default TimPeople;
