import MisiMSN from "../assets/images/VisiMisiImg/misi.svg"
import LayananMSN from "../assets/images/VisiMisiImg/layanan.svg"
import VisiMSN from "../assets/images/VisiMisiImg/visi.svg"

const visiMisi = [
    {
        id: 1,
        title: "Misi",
        icon: <img src={MisiMSN} alt="misi RAR PRODUCTION" />,
        description:
            "Menjadi pemimpin di industri Digital Platforms, Event Organizer, Video Production, dan Aktivitas Penerbitan Lainnya.",
    },
    {
        id: 2,
        title: "Layanan",
        icon: <img src={LayananMSN} alt="layanan RAR PRODUCTION" />,
        description:
            "Dapatkan loyalitas konsumen, berikan mereka pengalaman yang berkesan dengan menciptakan hubungan yang saling menguntungkan.",
    },
    {
        id: 3,
        title: "Visi",
        icon: <img src={VisiMSN} alt="visi RAR PRODUCTION" />,
        description:
            "Memberikan layanan berkualitas tinggi dengan kepercayaan, ketepatan, dan keseimbangan.",
    },
];

export default visiMisi;
