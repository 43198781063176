import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { BottomLine } from "../../../components";
import { useInView } from "react-intersection-observer";
import {
    sectionBodyAnimation,
} from "../../../hooks/useAnimation"

const Tentang = () => {
    const [ref, inView] = useInView();
    const [viewDiv, setViewDiv] = useState(false);
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            setViewDiv(true);
        } else {
            setViewDiv(false);
        }
    }, [inView, animation]);
    return (
        <div className="pt-2 my-16 parent">
            <div className="">
                <motion.div
                    className="mb-12"
                    initial={{ y: -200, opacity: 0 }}
                    animate={{
                        y: 0,
                        opacity: 1,
                        transition: { duration: 1, type: "spring" },
                    }}
                >
                    <h3 className="text-center text-accent">RAR PRODUCTIONS - Your Dream is our work</h3>
                    <h1 className="text-4xl font-semibold text-center drop-shadow-md">
                        Tentang <span className="text-primary">Kami</span>
                    </h1>
                    <BottomLine />
                </motion.div>
                <div className="items-center px-5 pt-4 text-center sm:px-20">
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={viewDiv && "visible"}
                        variants={sectionBodyAnimation}
                    >
                        <p className="font-medium text-accent">
                        RAR Production, didirikan pada bulan Januari 2019, dengan visi menjadi Perusahaan Rumah Produksi dan Even terkemuka di Provinsi Bengkulu. Kami bangga dapat mewujudkan dan meningkatkan citra berbagai acara, mulai dari pertemuan berskala kecil hingga besar. Tim kami membawa pengalaman melimpah dalam mengkonsep dan melaksanakan berbagai acara dan teknologi. Dipercaya oleh banyak perusahaan lokal dan nasional, RAR Production's konsisten memberikan hasil kreatif dan fantastis, meraih umpan balik positif dan kepuasan dari klien kami. Komitmen kami adalah memberikan layanan dengan perencanaan dan produksi dari awal hingga akhir, memastikan bahwa "Mimpi anda adalah totalitas kami."</p>
                        <p> Melanjutkan komitmen kami terhadap inovasi, RAR Production's dengan bangga memperkenalkan divisi terbaru kami yang berfokus pada pengembangan platform digital dan aplikasi. Memanfaatkan keahlian kami dalam organisasi acara, kami sekarang memperluas kemampuan kami untuk menciptakan pengalaman digital yang mulus dan menarik. Dari situs web interaktif hingga aplikasi seluler, kami berkomitmen untuk menyediakan solusi terkini yang disesuaikan dengan kebutuhan klien kami. Mimpi digital Anda kini menjadi bagian dari portofolio kami yang terus berkembang.
                        </p>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default Tentang;
