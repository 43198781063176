import React, { useEffect, useState } from "react";
import PortfolioData from "../../utils/Portofolio";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link, useLocation } from "react-router-dom";
import { Button, Card } from 'react-bootstrap';
import { FaAngleRight, FaWhatsapp } from 'react-icons/fa';
import "./portofolio.css";
import { headingAnimation, sectionBodyAnimation } from "../../hooks/useAnimation";
import { BottomLine} from "../../components";

const Portofolio = () => {
  const [portfolioData, setPortfolioData] = useState(PortfolioData);
  const [activeBtn, setActiveBtn] = useState("all");
  const location = useLocation();
  const [ref, inView] = useInView();
  const [viewDiv, setViewDiv] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
    if (location.pathname === "/" && portfolioData.length > 4) {
      setPortfolioData(portfolioData.slice(0, 4));
    }
  }, [inView, animation, location, portfolioData]);

  const handleFilter = (category) => {
    const filtered = category === "all" ? PortfolioData : PortfolioData.filter((item) => item.category === category);
    setPortfolioData(filtered);
    if (filtered.length > 4 && location.pathname === "/") {
      setPortfolioData(filtered.slice(0, 4));
    }
  };

  useEffect(() => {
    handleFilter("all");
  }, []);

  const handleOnClick = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={`${location.pathname !== "/" && "pt-16"}`}>
      <div className="py-16 parent ">
        <motion.div
          initial="hidden"
          animate={viewDiv && "visible"}
          variants={headingAnimation}
        >
          <div className="mb-6">
            <h1 className="text-4xl font-semibold text-center">
              Our <span className="text-primary">Projects</span>
            </h1>
            <BottomLine />
          </div>
        </motion.div>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={viewDiv && "visible"}
          variants={sectionBodyAnimation}
        >
          <div className="flex flex-wrap items-center justify-center mt-2 mb-6">
          <button
              className={`btn btn-sm bg-primary border-2 border-primary text-white hover:bg-transparent hover:border-primary hover:text-primary duration-300 mx-3 my-3 sm:my-0 ${activeBtn === "wedding" && "active-btn"
                }`}
              onClick={() => {
         
            setActiveBtn("all");
            handleFilter('all');
        }}
      >
        Show All
      </button>
            <button
              className={`btn btn-sm bg-primary border-2 border-primary text-white hover:bg-transparent hover:border-primary hover:text-primary duration-300 mx-3 my-3 sm:my-0 ${activeBtn === "wedding" && "active-btn"
                }`}
              onClick={() => {
                setActiveBtn("Digital Platforms");
                handleFilter("Digital Platforms");
              }}
            >
              Aplikasi Platform Digital
            </button>

            <button
              className={`btn btn-sm bg-primary border-2 border-primary text-white hover:bg-transparent hover:border-primary hover:text-primary duration-300 mx-3 my-3 sm:my-0 ${activeBtn === "tokoonline" && "active-btn"
                }`}
              onClick={() => {
                setActiveBtn("Even Organizer");
                handleFilter("Even Organizer");
              }}
            >
              Even Organizer
            </button>

            <button
              className={`btn btn-sm bg-primary border-2 border-primary text-white hover:bg-transparent hover:border-primary hover:text-primary duration-300 mx-3 my-3 sm:my-0 ${activeBtn === "companyprofile" && "active-btn"
                }`}
              onClick={() => {
                setActiveBtn("Video dan Penerbitan");
                handleFilter("Video dan Penerbitan");
              }}
            >
              Video dan Penerbitan
            </button>
          </div>
      <div className="flex flex-wrap items-center justify-center mt-6 mb-6">
      {activeBtn === "Show All" && (
              <h3 className="text-3xl font-semibold text-center" onClick={() => {
                handleFilter("all");
                setActiveBtn("Show All");
              }}>
                Semua Project <span className="text-primary">Kami</span>
              </h3>
            )}
      {activeBtn === "Digital Platforms" && (
              <h3 className="text-3xl font-semibold text-center" onClick={() => {
                handleFilter("Digital Platforms");
                setActiveBtn("Digital Platforms");
              }}>
                Project Digital Platforms <span className="text-primary">Kami</span>
              </h3>
            )}
            {activeBtn === "Even Organizer" && (
              <h3 className="text-3xl font-semibold text-center" onClick={() => {
                handleFilter("Even Organizer");
                setActiveBtn("Even Organizer");
              }}>
                Project Even Organizer <span className="text-primary">Kami</span>
              </h3>
            )}
            {activeBtn === "Video dan Penerbitan" && (
              <h3 className="text-3xl font-semibold text-center" onClick={() => {
                handleFilter("Video dan Penerbitan");
                setActiveBtn("Video dan Penerbitan");
              }}>
                Video dan Penerbitan <span className="text-primary">Kami</span>
              </h3>
            )}
          </div>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 ">
        {portfolioData.map(project => (
            <motion.div
            initial={{ x: 200, opacity: 0, scale: 0 }}
            animate={{
              x: 0,
              scale: 1,
              opacity: 1,
              transition: { duration: 0.3 },
            }}
            key={project.id}
            className="flex flex-col p-3 duration-500 rounded-lg shadow-lg impactfull-card"
          >
          <Card key={project.id} className="project mb-3">
            <Card.Img variant="top"  src={project.image} alt={project.title} className="rounded" />
            <Card.Body>
              <Card.Title> <h3 className="text-xl justify-center text-center font-semibold uppercase text-accent">{project.title}</h3></Card.Title>
              <Card.Text>
                <center>
              <button className="justify-center text-center flex items-center gap-3 text-black duration-500 bg-transparent border-2 btn border-primary hover:bg-primary hover:border-transparent hover:text-white">
                     
                      <span className="text-button-mobile justify-center text-center">{project.category}</span>
                    </button>
                    </center>
              </Card.Text>
            </Card.Body>
          </Card>
          <div className="w-full h-[1px] bg-primary inline-block align-bottom mb-4"></div>
          <div className="text-accent justify-center text-justify">
  {showAll ? project.description : project.description.slice(0, 200)}
  {project.description.length > 200 && (
    <span className="py-4 text-base font-medium text-center cursor-pointer text-accent hover:text-primary" style={{ display: "flex", justifyContent: "center" }}>
      <motion.button
        initial={{ y: -500 }}
        animate={{ y: 1 }}
        transition={{ duration: 0.5, type: "spring" }}
        onClick={() => setShowAll(!showAll)}
      >
        <span>{showAll ? "Lihat Lebih Sedikit" : "Lihat Lebih Banyak"}</span>
      </motion.button>
    </span>
  )}
</div>
              </motion.div>
            ))}
          </div>
        </motion.div>
        {location.pathname === "/" && (
          <div className="mt-8 text-right">
            <Link
              to="/paket"
              className="duration-300 text-1xl hover:text-primary"
            >
              <button className="primary-button ">
                <span>Lihat Semua</span>
                <span>
                  <FaAngleRight />
                </span>
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default Portofolio;
