const PortofolioData = [
    {
      id: 1,
      title: 'Aplikasi JDIH Kab. Lebong',
      description: 'Aplikasi JDIH (Jaringan Dokumentasi dan Informasi Hukum) adalah solusi modern yang dirancang khusus untuk Pemerintah Daerah Kabupaten Lebong dengan fokus pada manajemen dokumen dan informasi hukum. Proyek Kerjasama dengan NEOX Jogja ini bertujuan untuk meningkatkan aksesibilitas, keamanan, dan efisiensi dalam pengelolaan data hukum, mendukung transparansi, dan mempercepat proses pengambilan keputusan. Fitur-fitur utama Aplikasi JDIH meliputi manajemen dokumen yang terorganisir, memungkinkan pengguna untuk mengunggah, mengunduh, dan mencari dokumen hukum dengan mudah. Informasi hukum dapat diakses secara terpusat, termasuk peraturan daerah, keputusan kepala daerah, dan dokumen hukum lainnya. Sistem pencarian yang canggih memungkinkan pengguna untuk dengan cepat menemukan dokumen yang diperlukan, dan lapisan keamanan yang kuat melindungi integritas serta kerahasiaan data hukum. Selain itu, Aplikasi JDIH menyediakan notifikasi dan pembaruan terkait perubahan hukum, serta riwayat revisi dokumen untuk melacak evolusi dokumen dari waktu ke waktu.',
      category: 'Digital Platforms',
      image: '/projects/jdih.png',
    },
    {
      id: 2,
      title: 'Sistem Manajemen Informasi Publik',
      description: 'Sistem Informasi Manajemen Informasi Publik yang kami rancang khusus untuk Institusi Kantor Kesehatan Pelabuhan (KKP) Wilayah Bengkulu bertujuan mempermudah akses dan pengelolaan informasi publik terkait kesehatan pelabuhan. Dengan fokus pada transparansi dan akuntabilitas, sistem ini memungkinkan instansi tersebut untuk menyajikan informasi kesehatan pelabuhan secara jelas dan cepat kepada masyarakat. Keunggulan sistem melibatkan penyajian data yang terorganisir, pembaruan informasi yang real-time, dan kemampuan untuk memberikan informasi kesehatan yang akurat dan terpercaya. Dengan implementasi Sistem Informasi Manajemen Informasi Publik, Institusi Kantor Kesehatan Pelabuhan dapat meningkatkan keterbukaan informasi, memperkuat hubungan dengan masyarakat, dan mendukung tugas pokoknya dalam menjaga dan meningkatkan kesehatan pelabuhan secara efektif.',
      category: 'Digital Platforms',
      image: '/projects/kkp.png',
    },
    {
        id: 3,
        title: 'SISTEM INFORMASI LATUN SIAGA (SILAGA)',
        description: 'Sistem Informasi Latun Siaga adalah aplikasi yang berguna sebagai alat untuk konservasi penyu di provinsi Bengkulu. Dirancang untuk memberikan kemudahan kepada penggunanya. SILAGA memiliki fitur pembuatan laporan serta fitur tindak lanjut yang berguna untuk konservasi penyu dan telurnya. Sistem Informasi ini adalah sistem berbasis web untuk admin dan Android untuk pengguna. Dengan memanfaatkan GPS yang memudahkan pengguna untuk menentukan lokasi di mana penemuan ditemukan dan memudahkan penyelamat untuk melakukan tindakan penyelamatan.',
        category: 'Digital Platforms',
        image: '/projects/latun-silaga.jpg',
      },
      {
        id: 4,
        title: 'Point Of Sale UMKM Bengkulu',
        description: 'Sistem Informasi Point of Sale (POS) untuk UMKM merupakan solusi terbaik untuk manajemen penjualan yang efektif dan efisien. Didesain khusus untuk UMKM, sistem ini memberikan keunggulan dalam pelacakan inventaris, transaksi penjualan, dan laporan keuangan secara real-time. Keunggulan utama melibatkan kemudahan penggunaan dengan antarmuka yang intuitif, mempercepat proses check-out, dan mengoptimalkan stok barang. Klien kami, BrooVape Store, dengan bangga menggunakan sistem ini di keempat cabangnya di Bengkulu (Kabupaten Rejang Lebong, Seluma, dan Kota Bengkulu). Dengan implementasi POS, BrooVape Store dapat mengelola inventaris dengan lebih baik, meningkatkan layanan pelanggan, dan mempercepat pengambilan keputusan bisnis untuk pertumbuhan yang berkelanjutan.',
        category: 'Digital Platforms',
        image: '/projects/broovape.png',
      },
      {
        id: 5,
        title: 'PDKB (PLN) Raflesia Outbond',
        description: 'Proyek Outbond PDKB Raflesia yang dilaksanakan oleh PLN pada tanggal 3-4 Desember 2021 di Bukit Jipang, Kabupaten Rejang Lebong, merupakan perpaduan sempurna antara pembelajaran, kolaborasi, dan petualangan yang penuh keceriaan. Para peserta diajak untuk menjalani serangkaian kegiatan outdoor yang tak hanya membangun teamwork, tetapi juga menyegarkan semangat. Dari permainan tim yang seru hingga tantangan alam terbuka, setiap momen diisi dengan tawa, kegembiraan, dan koneksi yang erat di antara para peserta. Proyek ini tidak hanya menciptakan pengalaman yang tak terlupakan, tetapi juga memperkuat hubungan di antara anggota tim PLN, menciptakan memori yang penuh semangat untuk dikenang.',
        category: 'Even Organizer',
        image: '/projects/PKDB21.jpeg',
      },
      {
        id: 6,
        title: 'Outbound Rehabilitasi BNN Bengkulu',
        description: 'Pada tanggal 3 Juni 2021, BNN Bengkulu dan RAR Production menyelenggarakan event outbond yang sangat spesial di Lapas Kelas IIA Bentiring Kota Bengkulu. Acara ini menjadi wujud kolaborasi yang luar biasa dalam upaya meningkatkan kesadaran masyarakat terhadap bahaya penggunaan narkotika. Dengan penuh semangat, peserta terlibat dalam serangkaian kegiatan outbond yang tidak hanya mendebarkan, tetapi juga sarat makna. Melalui suasana yang penuh keceriaan, kami berharap pesan anti-narkoba dapat lebih mudah disampaikan dan diterima oleh masyarakat. Suksesnya acara ini tidak hanya tercermin dari antusiasme peserta, tetapi juga dari kolaborasi erat antara BNN Bengkulu dan RAR Production dalam menciptakan pengalaman yang berkesan dan mendidik.',
        category: 'Even Organizer',
        image: '/projects/OUTBOND Lapas.jpg',
      },
      {
        id: 7,
        title: 'Bazar DJIP Bengkulu',
        description: 'Pada Hari Oeang ke-77, Kemenkeu Satu Bengkulu menggelar Bazar UMKM dengan penuh semangat, hasil kerjasama DJIP dengan RAR Production. Pembukaan acara dipenuhi keceriaan, dimulai dengan senam bersama dan diresmikan secara simbolis dengan penabuhan dol oleh Kepala Perwakilan Kemenkeu Satu Bengkulu. Tari Nirmala dan penampilan live music turut memeriahkan acara, menciptakan suasana yang meriah. Sejumlah kegiatan menarik seperti donor darah, sosialisasi keuangan negara oleh KPP, sosialisasi LLAT oleh KPPN, dan Campus Goes to Kemenkeu (Universitas Dehasen) turut menyemarakkan hari pertama bazar. ',
        category: 'Even Organizer',
        image: '/projects/hari-oeang77.jpg',
      },
    // Add more projects as needed
  ];
  export default PortofolioData;