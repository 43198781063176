import { FaAddressCard, FaBullhorn, FaMobileAlt } from "react-icons/fa";

const Services = [
    {
      id: 1,
      title: "Digital Platforms",
      icon: <FaMobileAlt />,
      description:
        "Merancang dan mengembangkan platform digital yang inovatif, memberikan solusi terdepan dalam pengalaman pengguna digital. \n ✅ Mobile App Development \n ✅ Desktop App Development \n ✅ UI/UX Web/Mobile App Design \n ✅ Custom Web App Development",
    },
    {
      id: 2,
      title: "Event Organizer dan Video Production",
      icon: <FaBullhorn />,
      description:
        "Menghadirkan acara-acara yang tak terlupakan  dan Menciptakan karya-karya visual yang memukau dan berkesan, memadukan teknologi mutakhir dengan kreativitas tanpa batas. \n ✅ Pernikahan \n ✅ Konser \n ✅ Perjalanan \n ✅ Pesta Ulang Tahun \n ✅ Pertemuan \n ✅ Outbound ",
    },
    {
      id: 3,
      title: "Penerbitan & Advertising",
      icon: <FaAddressCard />,
      description:
        "Menyuarakan ide-ide inovatif melalui berbagai platform penerbitan, memberikan wawasan mendalam dan keterlibatan yang tak terhingga. \n ✅ Report \n ✅ Books \n ✅ Tiktok Ads \n ✅ Google Search Ads \n ✅ GDN & Youtube Ads \n ✅ Facebook & Instagram Ads \n ✅ Search Engine Optimization",
    },
  ];

  export default Services;