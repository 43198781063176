const blogs = [
  {
    _id: 1,
    title: "RAR PRODUCTION Menggelar Kegiatan Bazar UMKM dalam Rangka Hari Uang Ke-77",
    path: "rar-production-hari-oeang-djip",
    date: "24 Oktober 2023",
    img: "/blog/hari-oeang77.jpg",
    instagram_link:
      "https://www.instagram.com/rar_productions_/",
    linkedin_link:
      "#",
    tags: ["Hari OEANG", "77", "Bengkulu", "Bazar"],
    description:
      "Bengkulu, 24 Oktober 2023 – RAR PRODUCTION, perusahaan yang bergerak di bidang koordinasi acara, saat ini tengah aktif berpartisipasi dalam persiapan penyelenggaraan kegiatan bazar untuk mendukung Hari Oeang Ke-77. Kegiatan ini bertujuan untuk memberikan platform kepada pelaku UMKM (Usaha Mikro, Kecil, dan Menengah) guna mempromosikan produk-produk unggulan mereka.</p><p>      Acara ini sedang mengalami proses koordinasi teknis yang dipimpin oleh RAR PRODUCTION, yang berlangsung di Ruang Rapat Sinergi, Kantor Wilayah Direktorat Jenderal Perbendaharaan Bengkulu pada tanggal 24 Oktober 2023. Dalam pertemuan ini, pihak RAR PRODUCTION bersama dengan para pemangku kepentingan lainnya membahas detail teknis pelaksanaan bazar, termasuk pengaturan tempat, pembagian booth, dan jadwal acara.</p><p>Kegiatan bazar ini diharapkan dapat menjadi wadah bagi para pelaku UMKM untuk memamerkan dan menjual produk-produk berkualitas mereka, sekaligus memperkuat jejaring bisnis di tengah-tengah masyarakat. RAR PRODUCTION turut berkomitmen untuk menyelenggarakan acara ini dengan penuh keceriaan dan kerjasama, menciptakan momen yang berkesan untuk semua peserta dan pengunjung.</p><p>Pemilihan Hari Oeang Ke-77 sebagai momentum penyelenggaraan bazar ini menunjukkan dukungan RAR PRODUCTION terhadap upaya pemerintah dalam mendorong pertumbuhan sektor UMKM serta memperingati peran uang dalam pembangunan ekonomi nasional. Dengan antusiasme yang tinggi, RAR PRODUCTION berharap bahwa kegiatan ini akan memberikan dampak positif dan memajukan dunia UMKM di Bengkulu.</p>",
  },
  
  
];

export default blogs;
