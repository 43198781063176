const Reviews = [
  {
    id: 1,
    name: " Ibu Artiningsih, M.H.",
    bio: "Kabid Jaringan Informasi Hukum",
    img: "https://i.ibb.co/FVnbpjM/ibu-silvana.jpg",
    description:
      "Pengerjaan website luar biasa cepat, resolusi bagus, kecepatan bagus. Terima kasih NEOX Jogja dan RAR PRODUCTION.",
  },
  {
    id: 2,
    name: "Ari Anggoro",
    bio: "Direktur LATUN",
    img: "../testi/Ari-Anggoro.jpg",
    description:
      "Aplikasi ini adalah perubahan besar untuk upaya konservasi penyu di Bengkulu.👍👍",
  },
  {
    id: 3,
    name: "Ali Isha Wardhana, SKM., MKM.",
    bio: "Kepala KKP Bengkulu",
    img: "/testi/kkp.png",
    description:
      "Efisiensi sistem dan desain yang mudah digunakan membuatnya menjadi alat yang tak ternilai untuk mengelola informasi kesehatan di Bengkulu.",
  },
  {
    id: 4,
    name: "Romi Bastrian",
    bio: "Founder BROO VAPE STORE",
    img: "/testi/broovape.png",
    description:
      "Sistem POS yang diberikan kepada BrooVape telah menjadi kunci untuk mengoptimalkan operasional kami. 👌😁",
  },
];

export default Reviews;
